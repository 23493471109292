import { css } from '@emotion/react';
import { useResizeObserver, useViewportSize } from '@mantine/hooks';
import { pull } from 'lodash';
import { createContext, Fragment, useContext, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { usePrevious, useUpdateEffect, } from 'react-use';
import { proxy, ref } from 'valtio';
import { useProxy } from 'valtio/utils';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { FrLiteChartOfContext } from '~/pages/heineken_template/_fr/fr_liteChart/_OfContext';
import { component } from '~/utils/component';
import { apirc } from '~/configs/apirc';
import { FrLiteChartOfSocketDatafeed } from '~/pages/heineken_template/_fr/fr_liteChart/_OfSocketDatafeed';
import dayAPI from '~/utils/dayAPI';
import { barsToSma } from '~/pages/heineken_template/_fr/fr_liteChart/barsToSma';
/**
 * Lightweight-Charting SDK
 *
 * @see 文件 https://tradingview.github.io/lightweight-charts/tutorials/customization/intro
 */
export class FrLiteChart {
    static contextOfChart = createContext(
    // 只為了配置 typing
    null);
    /**
     * 預設 `ThemeConfig`
     *
     * @example
     *   //
     *   // 針對某個投顧層級的全局預設顏色
     *   if (fr_agents.is['futuresai@web']) {
     *     // 改...
     *     fr_liteChart.themes.dark.candlestickSeries = {
     *       upColor: '#ff4400',
     *       downColor: '#0080ff',
     *       wickUpColor: '#ff4400',
     *       wickDownColor: '#0080ff',
     *     }
     *     // 改...
     *     fr_liteChart.themes.dark.lineSeries = {
     *       color: '#d1d1d1',
     *     }
     *   }
     *
     *   export default templateProps.NextPage
     */
    themes = {
        dark: {
            chartOptions: {
                timeScale: {
                    rightOffset: 5,
                },
                localization: {
                    dateFormat: 'yyyy/MM/dd',
                    timeFormatter: (time) => {
                        return dayAPI(time * 1000).format('YYYY/MM/DD HH:mm');
                    },
                },
            },
            candlestickSeries: {
                upColor: '#ff4400',
                downColor: '#0080ff',
                wickUpColor: '#ff4400',
                wickDownColor: '#0080ff',
            },
            lineSeries: {
                color: '#d1d1d1',
            },
        },
        light: {
            chartOptions: {
                timeScale: {
                    rightOffset: 5,
                },
                localization: {
                    dateFormat: 'yyyy/MM/dd',
                    timeFormatter: (time) => {
                        return dayAPI(time * 1000).format('YYYY/MM/DD HH:mm');
                    },
                },
            },
            candlestickSeries: {
                upColor: '#f8916c',
                downColor: '#6cb5ff',
                wickUpColor: '#f8916c',
                wickDownColor: '#6cb5ff',
            },
            lineSeries: {
                color: '#202020',
            },
        },
    };
    /**
     * ### 官方原生 `.createChart()`
     *
     * ### 文件
     *
     * https://tradingview.github.io/lightweight-charts/tutorials/customization/intro
     *
     * ### 補充
     *
     * 與 ChartingLibrary 加載方式一樣。都是將 `<script>` 放在 `_document.page` 裡，讓它自己掛載到 `window` 物件上。
     *
     * 然後經由 `window.LightweightCharts.createChart` 取值，如果我們直接採用靜態 import 的話，會爆出 SSR 錯誤。
     *
     * - 參考 https://github.com/tradingview/lightweight-charts/issues/543
     * - 參考 https://github.com/tradingview/lightweight-charts/issues/446
     * - 參考 https://github.com/tradingview/lightweight-charts/issues/562
     *
     * @example
     *   //
     *   // 飯粒
     *   function Component() {
     *     const elementRef = useRef<HTMLDivElement>(null)
     *
     *     useEffect(() => {
     *       const chart = fr_liteChart.createChart(elementRef.current, {
     *         width: 300,
     *         height: 300,
     *       })
     *
     *       const series = chart.addSeries({})
     *
     *       return function GC() {
     *         chart?.remove()
     *       }
     *     }, [])
     *
     *     return <div ref={elementRef}></div>
     *   }
     */
    get createChart() {
        return globalThis.window.LightweightCharts.createChart;
    }
    /**
     * @example
     *   //
     *   // 飯粒
     *   return (
     *     <fr_liteChart.Chart
     *       symbol={'BTCUSDT'}
     *       interval={'1'}
     *       from={dayAPI().subtract(12, 'hours')}
     *     >
     *       <fr_liteChart.Kbars />
     *       <fr_liteChart.Tooltip />
     *     </fr_liteChart.Chart>
     *   )
     */
    Chart = ref(component(props => {
        const { current: store } = useRef(proxy(new FrLiteChartOfContext({
            themes: fr_liteChart.themes,
        })));
        store.symbol = props.symbol;
        store.interval = props.interval;
        return (<FrLiteChart.contextOfChart.Provider value={store}>
          <fr_liteChart.Canvas />
          <fr_liteChart.Datafeed from={props.from}/>
          {props.children}
        </FrLiteChart.contextOfChart.Provider>);
    }));
    Kbars = ref(component(props => {
        const store = fr_liteChart.useContext();
        useEffect(() => {
            if (!store.chart)
                return;
            const series = store.addSeriesOfKbars(store.chart.addCandlestickSeries(store.theme.candlestickSeries));
            return function GC() {
                store.removeSeriesOfKbars(series);
            };
        }, [store]);
        return <Fragment />;
    }));
    Tooltip = ref(component(props => {
        const store = this.useContext();
        useEffect(() => {
            if (!store.chart)
                return;
            if (!store.containerRef.current)
                return;
            const series = store.chart.addLineSeries({
                visible: false,
            });
            store.addSeriesOfKbars(series);
            //
            // 額外的浮框組件元素
            const tooltipContainer = document.createElement('div');
            tooltipContainer.id = 'el--' + Math.random().toString(36).slice(2);
            const tooltipRoot = createRoot(tooltipContainer);
            //
            // 附加進去 `<Chart />`
            store.containerRef.current.appendChild(tooltipContainer);
            //
            // 當 `<Chart />` 滑鼠在上面晃時，進行 Tooltip 邏輯
            const callbackOfMove = param => {
                if (param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > (store.containerRef.current?.clientWidth ?? 0) ||
                    param.point.y < 0 ||
                    param.point.y > (store.containerRef.current?.clientHeight ?? 0)) {
                    tooltipRoot.render(null);
                }
                else {
                    const price = param.seriesData.get(series);
                    if (price && 'value' in price) {
                        tooltipRoot.render(<div css={[
                                css `
                      position: absolute;
                      z-index: 1;
                      top: 0;
                      left: ${param.point.x}px;
                      transform: translateX(-50%);

                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      width: 120px;
                      height: 100%;
                      background-color: #f003;
                    `,
                            ]}>
                  <div css={css `
                      font-size: 18px;
                      font-weight: bold;
                      color: #1d27b6;
                    `}>
                    {fr_instrument.getName(store.symbol)}
                  </div>
                  <div css={css `
                      color: black;
                    `}>
                    {price.value}
                  </div>
                </div>);
                    }
                }
            };
            store.chart.subscribeCrosshairMove(callbackOfMove);
            return function GC() {
                if (store.chart) {
                    store.chart.unsubscribeCrosshairMove(callbackOfMove);
                    if (series) {
                        document.querySelector(`#${tooltipContainer.id}`)?.remove();
                        store.removeSeriesOfKbars(series);
                    }
                }
            };
        }, [store, store.chart, store.containerRef, store.symbol]);
        return <Fragment />;
    }));
    SMA = ref(component(props => {
        const store = this.useContext();
        useEffect(() => {
            if (!store.chart)
                return;
            if (!store.containerRef.current)
                return;
            const series = store.chart.addLineSeries({
                color: '#008ec7',
                lineWidth: 1,
                lastValueVisible: false,
                priceLineVisible: false,
            });
            const removeBarsOnChanged = store.addBarsOnChanged(data => {
                series.setData(barsToSma(data.bars, props.period));
            });
            return () => {
                store.chart?.removeSeries(series);
                removeBarsOnChanged();
            };
        }, [store, props.period]);
        return <Fragment />;
    }));
    useContext = () => {
        const store = useContext(FrLiteChart.contextOfChart);
        useProxy(store);
        return store;
    };
    Canvas = ref(component(props => {
        const store = this.useContext();
        const viewportSize = useViewportSize();
        const [resizeRef, containerSize] = useResizeObserver();
        useEffect(function createChart() {
            if (!store.containerRef.current)
                return;
            store.create();
            return () => {
                store.chart?.remove();
                store.chart = null;
            };
        }, [store]);
        // 當視角寬高變化時，作 <canvas> resize 功能
        useEffect(function resizeCanvas() {
            store.chart?.resize(containerSize.width, containerSize.height);
        }, [
            containerSize.height,
            containerSize.width,
            store.chart,
            viewportSize.height,
            viewportSize.width,
        ]);
        return (<div className={props.className} ref={resizeRef} css={css `
            position: relative; // 使 <canvas> 可以小於它，作用於 <canvas> resize 功能
            display: grid;
            height: 100%;
            width: 100%;
          `}>
          <div id={store.containerId} ref={store.containerRef} css={css `
              position: absolute; // 使 <canvas> 可以小於它，作用於 <canvas> resize 功能
              width: 100%;
              height: 100%;
            `}>
            {props.children}
          </div>
        </div>);
    }));
    Datafeed = ref(component(props => {
        const store = fr_liteChart.useContext();
        const symbolOfPrev = usePrevious(store.symbol);
        const intervalOfPrev = usePrevious(store.interval);
        /** 未來 K棒 持續更新資料（從 socket） */
        const onDataCbRef = useRef((data, subscriberUID) => {
            console.log('!!! 🍏 onDataCbRef', subscriberUID, data);
            if (subscriberUID !== `${store.symbol}_#_${store.interval}`)
                return;
            store.kbars.prepareNewBars(data);
        });
        /** 一次撈取完整的前些時間段的 K棒歷史資料 */
        const historyKbars = apirc.tvAPIs.kbars.useSWR({
            symbol: store.symbol,
            resolution: store.interval,
            from: props.from?.startOf('day') || dayAPI().subtract(1, 'days').startOf('day'),
            to: dayAPI().endOf('day'),
        }, {
            revalidateOnFocus: false,
        });
        /** 如果有歷史 K棒資料（例如 symbol 或 interval 改變時）重新 reset 圖表 */
        useEffect(() => {
            if (historyKbars.loading || !historyKbars.data?.length)
                return;
            store.kbars.prepareNewBars([]);
            store.kbars.prepareResetBars(historyKbars.data);
        }, [historyKbars.data, historyKbars.loading, store.kbars]);
        /**
         * 負責組件「K棒接收函式引用」的註冊與註銷
         *
         * - 組件第一次 mount 時，註冊K棒更新程序
         * - 組件被 unmount 時，註銷K棒更新程序
         */
        useEffect(() => {
            const onDataCb = onDataCbRef.current;
            this.socketDatafeed.subscribedCallbacks.push(onDataCb);
            return () => {
                pull(this.socketDatafeed.subscribedCallbacks, onDataCb);
            };
        }, []);
        /** 當 props 變化時，同時註冊這一次的 remote 訂閱K棒 */
        useEffect(() => {
            this.socketDatafeed.subscribe({
                symbol: store.symbol,
                interval: store.interval,
            });
        }, [store.interval, store.symbol]);
        /** 當 props 變化時，同時取消前一次的 remote 訂閱K棒 */
        useUpdateEffect(() => {
            return () => {
                if (symbolOfPrev && intervalOfPrev) {
                    store.kbars.prepareNewBars([]);
                    this.socketDatafeed.unsubscribe({
                        symbol: symbolOfPrev,
                        interval: intervalOfPrev,
                    });
                }
            };
        }, [intervalOfPrev, symbolOfPrev]);
        useEffect(() => {
            store.resetSeriesOfKbars();
        }, [store, store.kbars.forReset]);
        useEffect(() => {
            if (store.kbars.forReset.length) {
                return;
            }
            store.upsertSeriesOfKbars();
        }, [store, store.kbars.new]);
        return <Fragment />;
    }));
    socketDatafeed = new FrLiteChartOfSocketDatafeed(apirc.chartServer.at(0));
}
export const fr_liteChart = proxy(new FrLiteChart());
