import { isError, pull } from 'lodash';
import { ref } from 'valtio';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { fr_liteChart } from '~/pages/heineken_template/_fr/fr_liteChart';
import { FrLiteChartOfKbars } from '~/pages/heineken_template/_fr/fr_liteChart/_OfKbars';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { barsToBars } from '~/pages/heineken_template/_fr/fr_liteChart/barsToBars';
export class FrLiteChartOfContext {
    symbol;
    interval;
    /** `lightweight-charts` 的 `chart` instance (aka IChartApi) */
    chart = null;
    /** 從後端接到的K棒資料存在這 */
    kbars = new FrLiteChartOfKbars();
    containerRef = ref({ current: null });
    containerId = 'el--' + Math.random().toString(36).slice(2);
    /**
     * 組件層 `ThemeConfig`
     *
     * - 理論上可實現「非常細粒度」的「各個組件 Theme」都不同
     * - - （流汗）
     */
    themes = {
        dark: {},
        light: {},
    };
    seriesOfKbarsMapp = [];
    constructor(options) {
        this.symbol = options?.symbol || 'BTCUSDT';
        this.interval = options?.interval || '1';
        this.themes = options?.themes || {
            dark: {},
            light: {},
        };
    }
    create() {
        if (!this.containerRef.current) {
            throw new Error(`你必須先渲染圖表容器元素`);
        }
        this.chart = ref(fr_liteChart.createChart(this.containerRef.current, this.theme.chartOptions));
        return this.chart;
    }
    addSeriesOfKbars(series) {
        if (!this.chart)
            return;
        this.seriesOfKbarsMapp.push(ref(series));
        return series;
    }
    removeSeriesOfKbars(series) {
        if (!series)
            return;
        if (!this.chart)
            return;
        this.chart.removeSeries(series);
        pull(this.seriesOfKbarsMapp, series);
    }
    resetSeriesOfKbars() {
        if (!this.seriesOfKbarsMapp.length)
            return;
        if (!this.kbars.hasBarsForReset)
            return;
        debugAPI.liteChart.log(`.resetToAllSeriesOfOHLC()`);
        this.seriesOfKbarsMapp.forEach(eachSeries => {
            try {
                eachSeries.setData(barsToBars(this.kbars.forReset.map(bar => ({ ...bar, time: bar.time / 1000 }))));
            }
            catch (error) {
                if (isError(error)) {
                    throw new Error(`eachSeries.setData(this.kbars.kbarsInitial) 錯誤`, { cause: error });
                }
            }
        });
        this.barsOnChangedMapp.forEach(eachCallback => {
            eachCallback({ bars: this.kbars.all });
        });
        //
        // 已完成更新之後，要清掉序列
        this.kbars.forReset = [];
    }
    upsertSeriesOfKbars() {
        if (!this.seriesOfKbarsMapp.length)
            return;
        if (!this.kbars.hasNewBars)
            return;
        debugAPI.liteChart.log(`.upsertToAllSeriesOfOHLC()`);
        const newestBar = this.kbars.new.at(-1);
        if (!newestBar)
            return;
        this.seriesOfKbarsMapp.forEach(eachSeries => {
            try {
                newestBar.time =
                    String(newestBar.time).length >= 13 ? newestBar.time / 1000 : newestBar.time;
                eachSeries.update(barsToBars(newestBar));
            }
            catch (error) {
                if (isError(error) && error.message.includes(`Cannot update oldest data`)) {
                    throw new Error(`eachSeries.update(newestBar) 只能更新最近最新那一根K棒，不能更新第二根(含)以前的K棒`, { cause: error });
                }
            }
        });
        this.barsOnChangedMapp.forEach(eachCallback => {
            eachCallback({ bars: this.kbars.all });
        });
        //
        // 已完成更新之後，要清掉序列
        this.kbars.new = [];
    }
    /**
     * @example
     *   //
     *   useEffect(() => {
     *     if (!store.chart) return
     *     if (!store.containerRef.current) return
     *
     *     const series = store.chart.addLineSeries({
     *       color: '#008ec7',
     *       lineWidth: 1,
     *       lastValueVisible: false,
     *       priceLineVisible: false,
     *     })
     *
     *     const removeBarsOnChanged = store.addBarsOnChanged(data => {
     *       series.setData(barsToSma(data.bars, props.period))
     *     })
     *
     *     return () => {
     *       store.chart?.removeSeries(series)
     *       removeBarsOnChanged()
     *     }
     *   }, [store, props.period])
     */
    addBarsOnChanged(callback) {
        this.barsOnChangedMapp.push(callback);
        return () => {
            return this.removeBarsOnChanged(callback);
        };
    }
    removeBarsOnChanged(callback) {
        pull(this.barsOnChangedMapp, callback);
    }
    /** 當前對應配色主題的 `ThemeConfig` */
    get theme() {
        return this.themes[useThemeStore.getState().theme];
    }
    barsOnChangedMapp = [];
}
